require("./adverts.scss");
require("./legacy-ads.scss");

(function ($) {
    function resize() {
        const ad = $(".adverts-sidebar");
        if ($(window).height() < 768 && ad.height() > 600)
            ad.addClass("small-screen");
        else
            ad.removeClass("small-screen");
    }
    resize();
    
    const adResize = new ResizeObserver(entries => {
      entries.forEach(entry => {
       if (entry.contentRect.height > 50 ) 
          $('.ad-unit-top-leaderboard').addClass('ad-loaded') 
        else 
          $('.ad-unit-top-leaderboard').removeClass('ad-loaded');
      });
    });
    
    const adWrapper = document.querySelector('.ad-unit-top-leaderboard');
    if (adWrapper) adResize.observe(adWrapper);

    $(window).on("load scroll resize", resize);

})(jQuery);
